<template>
  <Form
    @submit="onRecoverPassword"
    :validation-schema="{
      email: 'email',
    }"
  >
    <div class="mb-6">
      <div class="relative w-full flex flex-wrap items-stretch">
        <span
          class="
            absolute
            z-10
            py-3
            pl-3
            w-8
            h-full
            leading-snug
            bg-transparent
            rounded
            text-base
            font-normal
            text-gray-400 text-center
            flex
            items-center
            justify-center
          "
        >
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1889 12.4936C12.8521 11.6957 12.3632 10.9709 11.7497 10.3596C11.1379 9.74661 10.4133 9.25785 9.61573 8.92034C9.60858 8.91677 9.60144 8.91499 9.5943 8.91141C10.7068 8.10784 11.43 6.79891 11.43 5.32213C11.43 2.8757 9.44787 0.893555 7.00144 0.893555C4.55501 0.893555 2.57287 2.8757 2.57287 5.32213C2.57287 6.79891 3.29608 8.10784 4.40858 8.9132C4.40144 8.91677 4.3943 8.91856 4.38715 8.92213C3.58715 9.25963 2.8693 9.74356 2.25323 10.3614C1.64021 10.9731 1.15145 11.6978 0.81394 12.4953C0.482372 13.2762 0.30355 14.1133 0.287154 14.9614C0.286677 14.9805 0.29002 14.9994 0.296986 15.0172C0.303951 15.0349 0.314399 15.0511 0.327712 15.0647C0.341025 15.0784 0.356935 15.0892 0.374504 15.0966C0.392074 15.104 0.410947 15.1079 0.430011 15.1078H1.50144C1.58001 15.1078 1.64251 15.0453 1.6443 14.9686C1.68001 13.59 2.23358 12.2989 3.21215 11.3203C4.22465 10.3078 5.5693 9.7507 7.00144 9.7507C8.43358 9.7507 9.77822 10.3078 10.7907 11.3203C11.7693 12.2989 12.3229 13.59 12.3586 14.9686C12.3604 15.0471 12.4229 15.1078 12.5014 15.1078H13.5729C13.5919 15.1079 13.6108 15.104 13.6284 15.0966C13.6459 15.0892 13.6619 15.0784 13.6752 15.0647C13.6885 15.0511 13.6989 15.0349 13.7059 15.0172C13.7129 14.9994 13.7162 14.9805 13.7157 14.9614C13.6979 14.1078 13.5211 13.2775 13.1889 12.4936ZM7.00144 8.39356C6.1818 8.39356 5.41037 8.07391 4.83001 7.49356C4.24965 6.9132 3.93001 6.14177 3.93001 5.32213C3.93001 4.50248 4.24965 3.73106 4.83001 3.1507C5.41037 2.57034 6.1818 2.2507 7.00144 2.2507C7.82108 2.2507 8.59251 2.57034 9.17287 3.1507C9.75322 3.73106 10.0729 4.50248 10.0729 5.32213C10.0729 6.14177 9.75322 6.9132 9.17287 7.49356C8.59251 8.07391 7.82108 8.39356 7.00144 8.39356Z"
              fill="#0057FF"
            />
          </svg>
        </span>
        <Field v-model="email" name="email" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            autocomplete="username"
            class="
              relative
              py-3
              px-2
              pl-10
              w-full
              bg-white
              rounded-lg
              border
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Correo electrónico:"
          />
        </Field>
      </div>
      <div class="text-left">
        <ErrorMessage name="email" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        class="rounded bg-primary py-2 px-4 text-white w-full"
        type="submit"
      >
        Enviar correo electrónico
      </button>
    </div>
  </Form>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { Field, Form, ErrorMessage, useField } from "vee-validate";
import Alert from "@/components/inc/Alert";

const store = useStore();

const { value: email } = useField("email");
let responseType = ref(null);
let responseMessage = ref(null);

const onRecoverPassword = async () => {
  try {
    responseType.value = null;
    responseMessage.value = null;

    await store.dispatch("forgotPassword", {
      email: email.value,
    });

    responseType.value = "success";
    responseMessage.value =
      "Se envió el correo para que recupere su contraseña";
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al recuperar contraseña";

    console.error(error);
  }
};
</script>
